var base = $("meta[name=base]").attr("content");

var $eventSelect = $(".search-box");

$eventSelect.on("select2:select", function (e) {
	if(e.params.data.hasOwnProperty("category")) { 
		window.location.href = base + '/brands/' + e.params.data.slug; 
	} else {
		window.location.href = base + '/category/' + e.params.data.slug;
	}
});

$(".search-box").select2({
	ajax: {
		maximumSelectionLength: 1,
		placeholder: "Enter search phrase",
		url: base + '/api/search',
		cache: true,
		dataType: 'json',
		delay: 250,
		data: function (params) {
			return {
		    	name: params.term,
		    	keywords: params.term,
		    	//page: params.page
		  	};
		},
		processResults: function (data, params) {
			//params.page = params.page || 1;
			return {
				results: data,
				//pagination: {
				//	more: (params.page * 15) < data.total
				//}
			};
		},
		cache: true
	},
	escapeMarkup: function (markup) { return markup; }, // let our custom formatter work
	minimumInputLength: 1,
	templateResult: formatResult,
	templateSelection: formatResultSelection
});

function formatResult (repo) {
	
	if (repo.loading) return repo.name;

	if(!repo.hasOwnProperty("category")) {

		var markup = "<div class='select2-result-repository clearfix'>" +
		"<div class='select2-result-repository__avatar'><img src='" + base + "/img/logo.png' /></div>" +
		"<div class='select2-result-repository__meta'>" +
		  "<div class='select2-result-repository__title'>" + repo.name + "</div>";

		  markup += "<div class='select2-result-repository__statistics'>" +
			"<div class='select2-result-repository__forks'>Type: Category</div>" +
			"<div class='select2-result-repository__stargazers'>Number of Brands: " + repo.number_of_brands + "</div>" +
		"</div>" + "</div></div>";

	} else {

		var markup = "<div class='select2-result-repository clearfix'>" +
		"<div class='select2-result-repository__avatar'><img src='https://d2kemwmzy87i2a.cloudfront.net/logos/" + repo.logo + "' /></div>" +
		"<div class='select2-result-repository__meta'>" +
		  "<div class='select2-result-repository__title'>" + repo.name + "</div>";

		if (repo.description) {
			markup += "<div class='select2-result-repository__description'>" + repo.description + "</div>";
		}
	
		markup += "<div class='select2-result-repository__statistics'>" +
			"<div class='select2-result-repository__forks'><i class='fa fa-star'></i> " + repo.current_rating + "</div>" +
			"<div class='select2-result-repository__stargazers'>Category: " + repo.category.name + "</div>" +
			"<div class='select2-result-repository__watchers'>Category Rank: " + repo.category_rank + "</div>" +
		"</div>" + "</div></div>";
	
	}

	

	return markup;
}

function formatResultSelection (repo) {
	return repo.name;
}